html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1;
  background-color: #f1f1f1;
  width: 100%;
  min-height: 100vh;
}

.error {
  color: red;
}

nav {
  justify-content: space-between;
  font-size: large;
}

.navbar-collapse {
  justify-content: end;
  margin-right: 10px;
}

.page-title {
  color: black;
  font-weight: 700;
}

@media (min-width: 768px) {
  /* Adjust font size for larger screens (desktop) */
  .page-title {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  /* Adjust font size for smaller screens (mobile) */
  .page-title {
    font-size: 14px;
  }
}

hr {
  color: #1d2023;
  opacity: 100%;
  border: 2px solid #1d2023;
  border-radius: 4px;
}

/* Define the keyframes for the animation */
@keyframes shrink-close {
  0% {
    width: 233px;
  }
  100% {
    width: 80px;
  }
}

/* Define the keyframes for the animation */
@keyframes shrink-open {
  0% {
    width: 80px;
  }
  100% {
    width: 233px;
  }
}

/* Apply the animation class to the div */
.shrink-animation-close {
  animation: shrink-close 0.25s ease-in-out;
}

.shrink-animation-open {
  animation: shrink-open 0.1s ease-in-out;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
