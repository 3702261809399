.app-page-container {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .app-page-body {
    display: flex;
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }

  .app-page-content {
    flex: 1 1 auto;
    overflow-x: auto;
  }

  .app-card {
    box-shadow: none;
    -webkit-box-shadow: none;

    border: 1px #dee2e6 solid;
  }

  .app-page-footer {
    display: flex;
    font-size: small;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(0, 0, 0);
    color: white;
    text-align: left;

    color: #fff;
  }
}

.item-card {
  background-color: #84a4fc;
  border-radius: 6px;

  h3 {
    color: #1d2023;
  }
}

.edit-item-card {
  background-color: #84a4fc;
  border-radius: 6px;
  max-width: 45%;

  h3 {
    color: #1d2023;
  }
}

.setting-inner-div {
  background-color: #fff;
  height: 87vh;
  width: auto;
  border-radius: 20px;
}

.setting-left-sidebar {
  border-right: 2px solid rgba(155, 155, 155, 0.15);
  margin-top: 1%;
  margin-bottom: 1%;
}

.change-password-form {
  border: 2px solid rgba(155, 155, 155, 0.15);
  border-radius: 10px;
}

.new-item-card {
  background-color: white; /* Ensures visibility of the card */
  border-radius: 10px; /* Gives rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Creates the floating shadow effect */
  padding: 20px; /* Adds spacing inside the card */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effect */
}

.new-item-card:hover {
  transform: translateY(-1px); /* Slightly lifts the card on hover */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3); /* Increases shadow intensity on hover */
}
