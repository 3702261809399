@import "buttons";
@import "containers";
@import "login";
@import "table";

.dropdown-container {
  width: 300px;
}

.formlabel {
  color: #1d2023;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.generate-btn {
  position: relative;
  background-color: rgb(27, 27, 27);
  border-radius: 5px;
  padding: 15px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  color: #84a4fc;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-left: 1%;
}

.search-btn {
  position: relative;
  background-color: rgb(27, 27, 27);
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 36px;
  padding-right: 36px;
  box-sizing: border-box;
  color: #84a4fc;
  border: none;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.generate-btn:disabled {
  opacity: 0.5;
}

.small-btn {
  position: relative;
  background-color: #1d2023;
  border-radius: 5px;
  padding: 2px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  width: 80px;
  height: 30px;
  color: #84a4fc;
  border: none;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  margin-left: 1%;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__input-container {
  input {
    width: 100%;
    height: 2.5em;
    border-radius: 4px;
    border: 1px solid #1d20237a;
    padding-left: 0.5em;
  }
  input::placeholder {
    padding-left: 0.5em;
  }
}

input#customerName {
  width: 100%;
  height: 2.5em;
  border-radius: 4px;
  border: 1px solid #1d20237a;
}

nav {
  background-color: #1d2023;
}

.nav-link {
  color: #84a4fc;
}

.sideNav {
  min-height: 100vh;
  background-color: rgb(35, 35, 35);
  color: aqua;

  .sideNavHeader {
    .sideNavLogo {
      height: 100%;
      width: 75%;
    }
  }

  .sideNavList {
    display: flex;
    flex-direction: column;
    align-items: center;

    .sideNavItem {
      // background-color: #84a4fc;
      color: white;
      min-height: 75px;
      width: 100%;
      padding: 1em;
    }
    .sideNavItem:hover {
      background-color: white;
      color: black;
    }
  }
}

.card-title {
  font-weight: 700;
  font-size: 24px;
}

.edit-card-title {
  font-weight: 700;
  font-size: 14px;
}
