.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &.btn-bq {
    @include themify-gradient();
    color: #fff;
    border-radius: 3px;
    min-width: 10px;
    text-transform: none;
    font-size: 14px;
  }

  &.btn-pill {
    border-radius: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  &.btn-pill:hover {
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.btn-colour {
    text-transform: none;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;

    &.purple {
      background-color: $color-brand-secondary;
    }

    &.red {
      background-color: $color-brand-primary;
    }
  }

  &.btn-nofocus:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn-purple {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #a34aac;

  &.white-content {
    color: #fff;
    border-radius: 4px;
    text-transform: none;
  }
}

.btn-transparent {
  border: #fff solid 2px;
  background: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  min-width: 150px;

  &:hover {
    color: #fff;
  }
}

.btn-white {
  background: white;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border-radius: 4px;
  font-size: 14px;
}

.btn-color {
  border-radius: 15px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  width: 150px;
  border: none;

  &:hover {
    cursor: pointer;
  }

  &.blue {
    @include themify(background-color, color-bright-blue);
  }

  &.blue-inverse {
    border: 1px solid;
    @include themify(
      (color, border-color),
      (color-bright-blue, color-bright-blue),
      !important
    );
    background-color: transparent;
  }

  &.cloud {
    background-color: #efebe8;
  }

  &.white-inverse {
    border: 1px solid;
    border-color: white;
    background-color: transparent;
  }

  &.red {
    background-color: $crimson-red;
  }

  &.grey {
    background-color: $color-text-default;
  }

  &.brand {
    @include themify-gradient();
  }
}

.btn-color:focus {
  outline: none;
}

.ripple {
  background-position: center;
  transition: 0.8s;
}

.ripple:active {
  background-color: $color-border-main;
  background-size: 100%;
  transition: 0s;
}

.no-decoration-btn {
  background-color: transparent;
  border: 0px;
  padding: 0;
  cursor: pointer;

  &.collapse-toggle:focus {
    color: white;
  }

  &.no-pointer {
    cursor: default !important;
  }
}

.no-decoration-btn-auto-width {
  background-color: transparent;
  border: 0px;
  padding: 0;
  width: auto;
  cursor: pointer;

  &.collapse-toggle:focus {
    color: white;
  }
}

.disabled-no-decoration-btn {
  background-color: transparent;
  border: 0px;
  padding: 0;

  &.collapse-toggle:focus {
    color: white;
  }
}

.no-cursor-btn {
  background-color: transparent;
  border: 0px;
  padding: 0;
}

.no-decoration-btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
  color: gray;
}

.link-button {
  background-color: transparent;
  border: 0px;
  padding: 0;

  &.link-blue {
    color: $color-link-blue;
  }

  &:not(.disabled):hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.simple-tab-bar {
  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    background-color: #fff;

    li {
      background-color: #fff;

      button {
        position: relative;
        display: inline-block;
        background-color: #c3dbfd;
        border: 0;
        border-radius: 25px;
        padding: 1rem;
        font-weight: 500;
        color: #056dfa;

        label {
          color: #056dfa;
          font-size: 16px;
          cursor: pointer;
        }

        &.active {
          font-weight: 600;
          background-color: #a5c9fd;
        }

        &.disabled {
          background-color: #c4c4c4;
        }
      }

      button:hover {
        background-color: #a5c9fd;
      }
    }
  }
}
