@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
* {
  margin: 0;
  padding: 0;
  font-family: 'poppins', sans-serif;
}

.login-div {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  background: url('../../assets/wallpaper.jpg') no-repeat;
  background-position: center;
  background-size: cover;
}

.login-blur-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 570px;
  height: 500px;
  margin-left: 30px;
  margin-right: 30px;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(6px);

  .form-area {
    z-index: 5;
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: azure;
      font-size: 42px;
    }

    .inputbox {
      label {
        display: flex;
        justify-content: left;
        align-items: center;
        color: azure;
      }
      input {
        width: 250px;
      }
    }
  }
}

.login-btn {
  width: 75%;
  height: 40px;
  border-radius: 40px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}
